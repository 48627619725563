// tracking with facebook pixel - https://developers.facebook.com/docs/facebook-pixel/reference

export const trackEvent = (eventName) => {
  if (typeof window.fbq === 'function') {
    window.fbq('trackCustom', eventName);
  }
};

/**
 * Save utm params to local storage. Replace existing values if new values are found.
 * 
 * @returns void
 */
export const saveUtmParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const utmParams = {
    utm_source: urlParams.get('utm_source'),
    utm_medium: urlParams.get('utm_medium'),
    utm_campaign: urlParams.get('utm_campaign'),
    utm_term: urlParams.get('utm_term'),
    utm_content: urlParams.get('utm_content')
  };

  if (Object.values(utmParams).some(value => value)) {
    localStorage.setItem('utmParams', JSON.stringify(utmParams));
  }
}

export const getUtmParams = () => {
  const utmParams = localStorage.getItem('utmParams');
  if (!utmParams) {
    return null;
  }

  return JSON.parse(utmParams);
}
