/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import * as Sentry from '@sentry/react';
import config from 'variant/config.yml';
import { StateProvider } from './src/state/store';
import { saveUtmParams } from './src/utils/tracking';
import React from 'react';

// You can delete this file if you're not using it
export const onClientEntry = () => {
  saveUtmParams();
  Sentry.init({
    dsn: 'https://95d703519a814836a1fc3cd4e94d25b9@o214415.ingest.sentry.io/5600021',
    autoSessionTracking: false,
    environment: `${process.env.NODE_ENV}-${config.siteMetadata.lang}`,
    integrations: [new Sentry.Integrations.GlobalHandlers({onunhandledrejection: false})]
  });
};

export const wrapRootElement = ({ element }) => {
  return <StateProvider>{element}</StateProvider>;
}
